import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal"; // Import react-modal
import "../../index.css";
import { MaterialReactTable } from "material-react-table";
import Button from "@mui/material/Button";
import loadingGif from "../../loading.gif";
import loadingOrderGif from "../../loadingOrder.gif";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // Adjust width as needed
  },
};
function Dashboard() {
  const [allData, setAllData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [modalOrderIsOpen, setModalOrderIsOpen] = React.useState(false);
  const [orderSummary, setOrderSummary] = useState(null);
  const [confirmOrderLoader, setConfirmOrderLoader] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // Estado para el producto seleccionado
  const [productModalIsOpen, setProductModalIsOpen] = React.useState(false);
  const openModal = (summary) => {
    setOrderSummary(summary);
    setModalOrderIsOpen(true);
  };

  const closeModal = () => {
    setModalOrderIsOpen(false);
  };

  const openProductModal = (product) => {
    setSelectedProduct(product);
    setProductModalIsOpen(true);
  };

  const closeProductModal = () => {
    setProductModalIsOpen(false);
    setSelectedProduct(null);
  };

  

  const columns = useMemo(() => {
    const muiTableHeadCellPropsSettings = {
      sx: {
        fontSize: {
          xs: "10px",
          sm: "11px",
          md: "12px",
          lg: "13px",
          xl: "14px",
        },        
      },
    };
    return [
      {
        accessorKey: "Batch ID",
        header: "Batch ID",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        size: 130,
        Cell: ({ row }) => (
          <button  style={{background:"black" ,border: '1px solid white',color:'white',borderRadius:'16px' }}onClick={() => openProductModal(row.original)}>
           <b>{row.original["Batch ID"]}</b>
          </button>
        ),
      },
      {
        accessorKey: "Strain",
        header: "Strain",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        size: 130,
      },
      {
        accessorKey: "Cycle",
        header: "Cycle",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        size: 130,
      },
      {
        accessorKey: "Flavor/Aroma Profile",
        header: "Flavor/Aroma",
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        size: 130,
      },
      {
        accessorKey: "US & Canada",
        header: "US & Canada",
        size: 130,
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
      },
      {
        accessorKey: "Europe",
        header: "Europe",

        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
      },
      {
        accessorKey: "Quantity",
        header: "Quantity",
        size: 130,
        muiTableHeadCellProps: muiTableHeadCellPropsSettings,
        Cell: ({ row, cell, table }) => (
          <input
            type="number"
            value={cell.getValue() || ""}
            onChange={(e) => {
              const newData = [...allData];
              newData[row.index].Quantity = e.target.value;
              setAllData(newData);
              table.options.meta?.updateData(
                row.index,
                "Quantity",
                e.target.value
              );
            }}
          />
        ),
      },
    ];
  }, [allData]);

  useEffect(() => {
    const fetchData = async () => {
      const url = "https://fullagro-dataroom.vercel.app/api/fetchData";
      const token =
        "tpIFuYK3WLt2QxsvGhWrzX9BPzQbJ95jaUtUByQmAIF9kFVX5KWzGCxJPAiZJ9hjWtxchtfJd9rs43qV8jqqpLYYcbfDh2guiKVxGHhRdFOVrg6kMbGPmC0pulSHR5JljgPu9zviAMTcHHAZcFFmQKLfiwEJT5m6rCdv7qfAUulUBBWfOIsChkvOqa6yW8Kmxc1sYCDXkMSof2q2QjYkxsu3Ur0ROA6MUOqsJCb8JBjgz6MsMfcyYdC6mzbc3cfAphczsakY8yzYa5AxaH8iUDvwfpHTIDdKTy2wdLDTFvD8izak0Fwco4P7mYr88l6VVaHhWMc8UHMF2VdeZKe1s5QLplTx6h2kF9kecCTuhtpPsB7r52xDs7KXcfVsqbHf7C2EYbBOOWUEAfMjQzSAePLVLVeT4S4SsoU126iKYZKU0JHeedyP4mEIaQ5mqH2C6yUuE6EAm0j7pCYCgp8hl6D6qQm4K4kTAbEeRQ6DPagVrxLgKhZqw0Q6xyj97JYD";

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const formattedData = data.data.map((item) => ({
          ...item,
          Quantity: "",
        }));
        setAllData(formattedData);
      } catch (error) {
        console.error("Hubo un problema con la operación fetch:", error);
      }
    };

    fetchData();
  }, []);

  const handleCreateOrder = async () => {
    const selectedRows = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedRows.length === 0) {
      alert("Please select at least one row to create an order.");
      return;
    }

    // Verify that all Quantity fields are filled
    for (const index of selectedRows) {
      const row = allData[parseInt(index)];
      if (!row.Quantity) {
        alert(`Please enter the quantity for Strain Name: ${row["Strain"]}.`);
        return;
      }
    }

    const ordersData = {
      orders: selectedRows.map((index) => {
        const row = allData[parseInt(index)];
        return {
          batchID: row["Batch ID"],
          strain: row.Strain,
          cycle: row.Cycle,
          quantity: row.Quantity,
          stock: row["US & Canada"],
        };
      }),
    };

    openModal(ordersData.orders);
  };

  const handleConfirmOrder = async () => {
    setConfirmOrderLoader(true); // Mostrar el loader
    closeModal();
    const token = localStorage.getItem('token'); // Obtener el token JWT del localStorage
    console.log({token})
    try {
        const response = await fetch("http://93.127.163.108:8888/orders", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`, // Añadir token al encabezado de autorización
            },
            body: JSON.stringify({
                orders: orderSummary, // Aquí se envía el resumen de las órdenes
            }),
        });

        if (response.ok) {
            alert("Órdenes creadas exitosamente, recibiste una copia por correo.");
            setRowSelection({});
        } else {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error desconocido");
        }
    } catch (error) {
        console.error("Error al crear las órdenes:", error.message);
        if (error.message.includes("401")) {
            alert("Sesión inválida. Por favor, inicia sesión nuevamente.");
        } else {
            alert("Ocurrió un error al crear las órdenes. Por favor, intenta nuevamente.");
        }
    } finally {
        setConfirmOrderLoader(false); // Ocultar el loader independientemente del resultado
    }
};

  return (
    <div id="dashboard">
      {confirmOrderLoader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 9999,
          }}
        >
          <h2>
            <b>Creating Order.</b>
          </h2>
          <img
            src={loadingOrderGif}
            alt="Loading..."
            style={{
              width: "6rem",
              height: "6rem",
            }}
          />
        </div>
      )}
      {allData.length > 0 ? (
        <>
          <h1>Products Table</h1>
          <Button
            id="order-button"
            variant="contained"
            color="primary"
            onClick={handleCreateOrder}
            disabled={
              Object.keys(rowSelection).filter((key) => rowSelection[key])
                .length === 0
            }
          >
            Create Order
          </Button>
          <MaterialReactTable
            data={allData}
            columns={columns}
            defaultColumn={{
              maxSize: 130,
              minSize: 10,
              size: 130, //default size is usually 180,     
                       
            }}          
            enableStickyHeader
            /* enableEditing={true}
            onEditingRowSave={({ row, values }) => {
              const newData = [...allData];
              newData[row.index] = values;
              setAllData(newData);
            }} */
            mrtTheme={ (theme) => ({
              baseBackgroundColor: "#000",                      
                    
            })}
            muiTableBodyProps= {{
              sx: {
                //stripe the rows, make odd rows a darker color
                '& td , *': {
                  color: '#fff',
                },
                '& input': {
                  color: '#000',
                },
              },
            }}
            muiTableHeadProps={{
              sx: {
                zIndex:0,
                //stripe the rows, make odd rows a darker color
                '& *': {
                  color: '#fff',
                },
              },
            }}
            muiTopToolbarProps={{
              sx: {
                zIndex:0,
                //stripe the rows, make odd rows a darker color
                '& *': {
                  color: '#fff',
                },
                '& input': {
                  color: '#fff',
                },
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                zIndex:0,
                //stripe the rows, make odd rows a darker color
                '& *': {
                  color: '#fff',
                  display:'none'
                },
              },
            }}
            
            enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            enableFullScreenToggle={false}
            enable
            enableRowSelection
            enableSelectAll={false}
            onRowSelectionChange={setRowSelection}
            positionToolbarAlertBanner={'head-overlay'}
            state={{ rowSelection }}
          />
          <ProductInfoModal
            isOpen={productModalIsOpen}
            onRequestClose={closeProductModal}
            product={selectedProduct}
          />
          <Modal
            isOpen={modalOrderIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Order Summary"
          >
            <h2>Order Summary</h2>
            {orderSummary && (
              <ul>
                {orderSummary.map((item, index) => (
                  <li key={index}>
                    Batch ID: {item.batchID}, Strain: {item.strain}, Quantity:{" "}
                    {item.quantity}
                  </li>
                ))}
              </ul>
            )}
            <Button onClick={handleConfirmOrder}>Confirm Order</Button>
            <Button onClick={closeModal}>Cancel</Button>
          </Modal>
        </>
      ) : (
        <div>
          <p style={{ color: "white", textAlign: "center" }}>
            <b>Loading Products...</b>
          </p>
          <img
            src={loadingGif}
            alt="Cargando..."
            style={{
              margin: "auto",
              display: "block",
              width: "4rem",
              height: "4rem",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
