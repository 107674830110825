// ProductInfoModal.js
import React from 'react';
import Modal from 'react-modal';
import Button from '@mui/material/Button';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

const ProductInfoModal = ({ isOpen, onRequestClose, product }) => {
  console.log({product})
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Product Information"
    >      
      {product && (
        <div className='modal-content'>
          <div className='strain-name'><strong>{product.Strain}</strong></div>                                                                 
          <div className='genders'>
            <div>{product.Male}</div>
            <div>{product.Source}</div>
          </div>  
          <div className='images'>
            <div>{product["Plant Image"]}</div>
            <div>{product["Seed Image"]}</div>
          </div>  
          <div className='pdfs'>
            <div>{product["COA Link PDF"]}</div>
            <div>{product["Germination Test Link PDF"]}</div>
          </div>   
        </div>
      )}
      <Button onClick={onRequestClose}>Close</Button>
    </Modal>
  );
}

export default ProductInfoModal;