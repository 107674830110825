import React, { useState } from "react";
import Login from "./Components/Login/LoginComponent";
import Header from "./Components/Header/HeaderComponent";
import Dashboard from "./Components/Dashboard/DashboardComponent";
function Fullagroapp() {
  const [auth, setAuth] = useState(localStorage.getItem('token') ? true : false);

  const modAuthTrue = () => setAuth(true);

  const modAuthFalse = () => setAuth(false);

  return (
    <div id="app">
      <Header auth={auth} modAuthFalse={modAuthFalse} />
      {auth ? <Dashboard /> : <Login modAuthTrue={modAuthTrue} />}
    </div>
  );
}

export default Fullagroapp;
